<template>
    <div class="container">
        <div class="container-box">
            <div class="logo-box"><router-link to="/index"><img src="../../assets/images/logo.png" width="152" height="35"></router-link></div>
            <div class="nav-bar-box">
<!--                <div v-for="item in menus" :key="item.menuName" class="nav-btn"  :class="item.menuUrl==url?'btn-select':'btn-no-select'">-->
<!--                    <router-link :to="item.menuUrl">-->
<!--                        <b>{{item.menuName}}</b>-->
<!--                    </router-link>-->
<!--                </div>-->
            </div>
            <div class="login-box">
                <span v-if="!user"><router-link to="/Login">登录</router-link>/<router-link to="/Regist">注册</router-link></span>
                <span v-if="user">
					<span style="color: #00a854;padding-left: 1rem" class="loginout-btn" @click="loginout">退出登录</span>
					<span style="cursor:pointer" @click="myCenter" title="个人中心">{{user.userAccount}} 欢迎您!</span>
				</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        props:["url","type"],
        data:function () {
            return {
                menus:[],
                user:''
            }
        },
        created() {
            if (this.type && this.type==1){
                this.menus = [
                    {
                        menuName:"项目",
                        menuUrl:'/Projects',
                    },
                    {
                        menuName:"运力",
                        menuUrl:'/Transport',
                    },
                    {
                        menuName:"配套设施",
                        menuUrl:'/Support',
                    },
                    {
                        menuName:"申请流程",
                        menuUrl:'/Applyflow',
                    },
                ]
            }else{
                this.initMenus();
            }
            let userinfo = sessionStorage.getItem("userinfo");
            if (userinfo){
                this.user = JSON.parse(userinfo);
            }
        },
		methods:{
			//初始化菜单列表
			initMenus:function(){
				this.HTTP.get('/index/menus').then(res=>{
					if(res.data.code==1){
						this.menus = res.data.rows;
					}
				}).catch(err=>{
					console.log(err);
				})
			},
            loginout(){
                this.$confirm('确认退出?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    sessionStorage.removeItem("userinfo");
					sessionStorage.removeItem("userMsg");
					sessionStorage.removeItem("token");
					this.$store.state.token='';
                    this.user = ''
                    this.$message.success("退出成功")
                    this.$router.push("/index")
                }).catch(() => {
                });
            },
			myCenter:function(){
				this.$router.push("/MyCenter");
			}
		}
    }
</script>

<style lang="stylus" scoped>
.container
    width 100%
    height 6rem
    background-color white
.container-box
    width 120rem
    height 100%
    font-family Microsoft YaHei Regular, Microsoft YaHei Regular-Regular
    display flex
    flex-direction row
    flex-wrap nowrap
    justify-content left
    margin 0 auto
.logo-box
    width 25%
    height 100%
    padding 1rem 1rem
    text-align left
    line-height 6rem

.login-box
    width 30%
    height 100%
    padding-right 1rem
    span
        float right
        line-height 6rem
        font-size 1.4rem
        font-weight 400
        color #8faadc
.nav-bar-box
	.nav-btn:hover
		background-color cornflowerblue
		b
			color white
.nav-bar-box
    width 45%
    height 100%
    display flex
    flex-direction row
    flex-wrap nowrap
    justify-content left
    .nav-btn
        width 8.4rem
        height 100%
        text-align center
        b
            line-height 6rem
            font-size 1.4rem
    .btn-select
        background-color cornflowerblue
        b
            color white
    .btn-no-select
        background-color white
.loginout-btn
    cursor pointer

@media screen and (min-width: 320px)  and (max-width: 1024px)
    .container-box
        width 100%


</style>
