<template>
    <div>
        <newHeader url="/FalvIndex"></newHeader>
        <div style="background-color: #f4f4f4;z-index:-1000;position: sticky">
            <div class="top-container" style="background-color: #FFFFFF;padding-bottom: 25px;position: sticky">
                <div class="top-container-img">
                    <h2>重庆有理法律咨询有限公司</h2>
                    <div style="font-size: 60px;color: #eeeeee;margin-top: -21px;z-index: -999;position: absolute">
                        LEGAL ADVICE
                    </div>
                    <h5>选择有理，让法律服务触手可及</h5>
                    <img src="../assets/images/aboutUs01.png"/>
                </div>
                <div class="top-container-div">
                    <p>专注解决中小型企业及高净值客户的法律风险预防与控制的问题的法律服务公司，主要业务范围包括为企业提供法律顾问、法律咨询、
                        法律培训、商务谈判、大型项目风控、个人法律顾问等。公司以“互联网＋”的创新思维将法律服务呈现于线上，让服务流程标准化、
                        可视化，让服务结果更快速、更高效。有理拥有一批身经百战的律师队伍、服务过上百家企业经验的法务团队，包括建筑、房产、
                        制造、高科、信息、贸易、物流、食品、医疗、酒店和教育等众多行业，在公司股权架构设计、投融资风控、劳资纠纷处理、
                        买卖关系、租赁关、建设工程等多方面均有丰富经验。
                    </p>
                    <div class="border-box">
                        <div class="border-text"><span>优质</span></div>
                    </div>
                    <div class="border-box">
                        <div class="border-text"><span>高效</span></div>
                    </div>
                    <div class="border-box">
                        <div class="border-text"><span>快捷</span></div>
                    </div>

                    <p>
                        线上、线下结合的服务模式，满足客户多元化的法律需求。同时提供各种诉讼和非诉讼的法律服务，为中小企业的生存与发展保驾护航。
                        有理基于专业的法律知识和丰富的实操经验，积极提供企业管理层及员工的法律意识培训，坚持企业法律风险防控在先，从根源上减少企业
                        纠纷的产生，将风险扼杀于摇篮之中。有理与企业的合作过程中深入 流、共同进退，与众多企业建立着长期友好的合作关系。有理相信，
                        坚持做好服务本身，提高服务质量，贴合企业风控需求，为企业提供优质、高效、快捷的法律服务，是陪伴企业共同发展的明智选择。

                    </p>
                </div>
            </div>
            <div class="center-container">
                <div class="center-container-img">
                    <img src="../assets/images/falv01.png"/>
                </div>
                <div class="center-container-div">
                    重庆有理法律咨询有限公司成立于2021年7月26日，坐落于美丽的山城重庆，是一个充满朝气、蓬勃发展、有干劲的团队，
                    公司隶属上海维盈律师事务所企业服务单位，律所放眼重庆司法体系建设及法制发展情况，维盈律所特指派数名律师牵头成立有理法律咨询
                    公司旨为重庆市中小企业提供专业化、系统化法律服务，降低企业法律成本，保护企业合法权利，助力企业稳步发展！
                </div>
            </div>

            <div class="center-container">
                <div class="center-container-img">
                    <img src="../assets/images/falv02.png"/>
                </div>
                <div class="center-container-div">
                    第七十届世界小姐大赛时尚周（重庆站）协办单位 重庆市中小企业服务机构
                </div>
            </div>
            <div class="buttom-container">
              <el-card>
                  <el-image :src="require('@/assets/images/falv_1.png')"/>
                  <p>有理法律团队为会员企业现场提供全面内控服务，筛查梳理现存法律风险，建设企业内部持续发展法律生态体系</p>
              </el-card>
              <el-card>
                <el-image :src="require('@/assets/images/falv_2.png')"/>
                <p>有理法务团队坚持以专业、谨慎、细心的服务准则，为会员企业提供完善优质法律服务，全力保障业务合规开展</p>
              </el-card>
              <el-card>
                <el-image :src="require('@/assets/images/falv_3.png')"/>
                <p>重庆有理法律咨询团队为第70届世界小姐大赛保驾护航，全面参与项目风控，与世界小姐大赛合作相得益彰</p>
              </el-card>
              <el-card>
                <el-image :src="require('@/assets/images/falv_4.png')"/>
                <p>有理法律团队开展“普法进社区”免费法律咨询普法活动，我们深知社会法治建设重要性，也一直致力普法教育</p>
              </el-card>
            </div>

            <div class="title-box-1">
                PARTNER
            </div>
            <div class="title-box-2">
                <img src="../assets/images/partner.png" height="30" width="122" class="img-title"/>
                <div class="img-title">
                </div>
            </div>
            <div class="partner">
                <div class="container-box" style="height:23rem">
                    <div class="link-info" v-for="item in Links" :key="item.name">
                        <el-card @click.native="window.open(item.url)" >
                            <el-image :src="item.pic" @click="window.open(item.url)" fit="fill"/>
                        </el-card>
                    </div>
                </div>
            </div>
            <div class="title-box-1">
                CONSULT
            </div>
            <div class="title-box-2">
                <img src="../assets/images/consult.png" height="30" width="249" class="img-title"/>
                <div class="img-title">
                </div>
            </div>
            <div class="contacts">
                <baidu-map class="map" :center="{lng: 106.523, lat: 29.548}" :zoom="15" style="width: 1200px;height: 280px">
                    <bm-marker :position="{lng: 106.523, lat: 29.546}" :icon="{url: pointIcon, size: {width: 34, height: 34}}">
                    </bm-marker>
                    <bm-overlay
                            ref="myInfo"
                            pane="labelPane"
                            class="sample"
                            @draw="draw"
                            show="true"
                            :position="{lng: 106.515962, lat: 29.607453}"
                            @mouseover.native="active = true"
                            @mouseleave.native="active = false">
                        <div>
                            <el-image :src="require('@/assets/images/contacts.png')"/>
                            <span style="margin-left: 5px;">联系人：任芳芳</span>
                        </div>
                        <div>
                            <el-image :src="require('@/assets/images/contacts.png')"/>
                            <span style="margin-left: 5px;">邮 箱：renfangfnag2085@163.com</span>
                        </div>
                        <div>
                            <el-image :src="require('@/assets/images/phone.png')"/>
                            <span style="margin-left: 5px;">电 话：18084012085</span>
                        </div>
                        <div>
                            <el-image :src="require('@/assets/images/location.png')"/>
                            <span style="margin-left: 5px;">地 址：重庆市渝中区大坪街道英利中心壹号902</span>
                        </div>
                    </bm-overlay>
                </baidu-map>
            </div>
        </div>


		<div class="form-container">
		    <el-form :model="form" ref="form" style="width: 100%;">
		        <el-row :gutter="20">
		            <el-col :span="12">
		                <el-form-item label="您的姓名：">
		                    <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
		                </el-form-item>
		            </el-col>

		            <el-col :span="12">
		                <el-form-item label="您的联系方式：">
		                    <el-input v-model="form.phone" placeholder="请输入您的联系方式"></el-input>
		                </el-form-item>
		            </el-col>
		        </el-row>

		        <el-row>
		            <el-col :span="24">
		                <el-form-item label="您的疑问或事宜简述：">
		                    <el-input type="textarea" :rows="5" placeholder="请输入内容" resize="none" v-model="form.content"></el-input>
		                </el-form-item>
		            </el-col>
		        </el-row>
		        <el-form-item style="text-align: center;">
		            <el-button type="primary" @click="onSubmit" style="width: 180px;height: 40px;" round>提 交</el-button>
		        </el-form-item>
		    </el-form>
		</div>

        <buttom></buttom>
    </div>
</template>

<script>
    import newHeader from '@/components/common/newHeader'
    import Header from "@/components/common/Header";
    import Buttom from "@/components/common/Buttom";
    import Swipper from "@/components/common/index/Swipper";
    import FlowServiceTitle from "@/components/common/index/FlowServiceTitle";

    export default {
        name: "FalvIndex",
        data: function () {
            return {
                title: '重庆有理法律咨询有限公司',
                content: '选择有理，让法律服务触手可及',
                form: {
                    name: '',
                    phone: '',
                    content: ''
                },
                pointIcon: require('@/assets/images/point.png'),
                Links: [
                    {
                        url: "http://www.baidu.com",
                        pic: require('@/assets/images/partner/partner_1.png')
                    },
                    {
                        url: "http://www.baidu.com",
                        pic: require('@/assets/images/partner/partner_2.png')
                    },
                    {
                        url: "http://www.baidu.com",
                        pic: require('@/assets/images/partner/partner_3.png')
                    },
                    {
                        url: "http://www.baidu.com",
                        pic: require('@/assets/images/partner/partner_4.png')
                    },
                    {
                        url: "http://www.baidu.com",
                        pic: require('@/assets/images/partner/partner_5.png')
                    },
                    {
                        url: "http://www.baidu.com",
                        pic: require('@/assets/images/partner/partner_6.png')
                    }
                ]
            }
        },
        components: {
            Header,
            Buttom,
            Swipper,
            FlowServiceTitle,
            newHeader
        },
        methods: {
            draw({el, BMap, map}) {
                const pixel = map.pointToOverlayPixel(new BMap.Point(106.523003, 29.546356))
                el.style.left = pixel.x - 170 + 'px';
                el.style.top = pixel.y - 160 + 'px';
            },
            onSubmit: function () {
                if (!this.form.name) {
                    this.$message.warning('请输入您的姓名');
                    return false;
                }
                if (!this.form.phone) {
                    this.$message.warning('请输入您的联系方式');
                    return false;
                }

                if (!this.form.content) {
                    this.$message.warning('请输入内容');
                    return false;
                }
				this.HTTP.post('/index/submitMessage',this.form).then(res=>{
					if(res.data.code==1){
						this.$message.success('提交成功');
						this.form = {
							name: '',
							phone: '',
							content: ''
						}
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err=>{
					this.$message.error(err);
				})
            }
        }
    }
</script>
<style lang="stylus" scoped>
    .container-box
        width 123rem
        margin 0 auto

    .top-container
        width 123rem
        display flex
        margin 0 auto
        flex-direction row
        justify-content space-around
        align-content center
        margin-bottom 5rem

        h2
            font-size 30px
            color #000033

        h5
            font-size 16px
            color #666666

        span
            font-size 24px
            color #1a2fcd

        .top-container-img
            width 584px
            height 100%
            float left
            text-align left

            img
                width 100%
                height 100%
                border-radius 1px

        .top-container-div
            width 500px
            height 100%
            float left
            padding 20px 0px
            font-size 14px
            color #666666

    .center-container
        width 123rem
        height 15rem
        display flex
        margin 0 auto
        background-color #ffffff
        flex-direction row
        justify-content space-around
        align-content center
        margin-bottom 5rem
        font-size 14px
        color #666666

        .center-container-img
            width 290px
            height 150px
            float left
            text-align center

            img
                width 100%
                height 100%
                border-radius 0

        .center-container-div
            margin-left 5rem
            width 65%
            height 100%
            float left
            font-size 18px
            display flex
            align-items center
            justify-content left

    .buttom-container
        width 123rem
        height 25rem
        display flex
        margin 0 auto
        flex-direction row
        justify-content space-around
        align-content center
        margin-bottom 5rem
        font-size 14px
        color #666666

        .el-card
            text-align center
            font-size 12px
            color #999999

            h5
                font-size 14px
                color #666666

            p
                margin 10px

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .partner
        width 100%
        height 35rem
        background-color #000b25
        background url("../assets/images/aboutUs02.png")
        background-size 100% 100%

        .link-info
            .el-card
                width: 240px;
                /*height 80px*/
                float left
                margin 30px 65px 0px 0px

    .contacts
        text-align -webkit-center
        margin-bottom 50px

    .sample {
        background-image: url("../assets/images/aboutUsInfo.png");
        width: 340px;
        height: 150px;
        line-height: 20px;
        background-repeat: no-repeat;
        overflow: hidden;
        color: #fff;
        padding: 20px;
        position: absolute;
        background-position: bottom;
    }


    .title-box-1 {
        height: 200px;
        font-size: 52px;
        color: #ededed;
        line-height: 200px;
        z-index: -999;
        position: absolute;
        width: 100%;
        letter-spacing: 5px;
        text-align: center;
    }

    .title-box-2 {
        padding: 65px 0;
        text-align: center;
    }

    .img-title:after {
        content: '';
        width: 50px;
        height: 1px;
        display: block;
        margin: 0 auto;
        border-bottom: 3px solid #1a4fcd;
    }

	.border-box{
		height: 85px;
		width: 85px;
		text-align: center;
		margin: 12px 36px;
		padding 2px;
		border-radius:350px
		background-image: linear-gradient(to bottom , #1a4fcd, #2ED);
		display: inline-block;
	}
    >>> .anchorBL {
        display none
    }
    .border-text {

        height: 100%;
        width: 100%;
        text-align: center;
		background-color: #ffffff;
        border-radius: 350px;
        font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
    }

    .form-container
        width 100rem
        height 30rem
        display flex
        margin 0 auto
        flex-direction row
        justify-content left
        align-content center
        margin-bottom 5rem
        padding-top 10px
        padding-left 5rem
        padding-right 5rem

	>>> .anchorBL {
	    display none
	}
</style>
